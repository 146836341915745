import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '11px',
  width = '20px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.941011 0L0 0.946085L10 11L20 0.946085L19.059 0L10 9.10783L0.941011 0Z"
        fill={color}
      />
    </svg>
  );
}
