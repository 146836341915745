import React from 'react';
import { Menu, Container, Icon } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from '../../utils/useRouter';
import Responsive from "../../components/Responsive/Responsive";
import PredictIcon from '../Icons/PredictIcon';
import UsageIcon from '../Icons/UsageIcon';
import NotificationIcon from '../Icons/NotificationIcon';
import PayNowIcon from '../Icons/PayNowIcon';
import { withContexts } from '../../contexts';
import { iconSearch } from '../../utils/IconsMap';
import Logo from '../Logo/Logo';
import LogoBusiness from '../Logo/LogoBusiness';
import UserIcon from "../Icons/UserIcon";
import HeaderHelpIcon from "../Icons/HeaderHelpIcon";
import './Header.css';
import { defaultTheme, withTheme } from '../../contexts/Theme';

export class Header extends React.Component {
  
  NavListItems = (props) => {
    const { customer, pageConfig } = this.props.customer;

    if (!pageConfig || !pageConfig.menuItems || !customer) return null;

    const iconHeight = 24; 

    // Filter out Power Hours if customer type is business
    const filteredNavigationMenu = pageConfig.menuItems.navigationMenu.filter(item => {
      if (customer.customerType === "business" && item.text === "Power Hours") {
        return false;
      }
      return true;  // Include all other items
    });

    return filteredNavigationMenu
      .sort((a, b) => a.order - b.order)
      .map((navItem) => {
        return (
          <Menu.Item 
            key={navItem.order} 
            as={NavLink} 
            to={navItem.url} 
            replace 
            className="menu-item-icon"
            style={props.style}
            aria-label={navItem.text} // Adding aria-label for screen readers
            role="menuitem" // Defining the role as a menu item
            tabIndex="0" // Making the item focusable
          >
            {iconSearch(navItem.icon, { count: navItem.count, height: iconHeight, width: iconHeight })}
            <span>{navItem.text}</span>
          </Menu.Item>
        );
      });
  };

  render() {
    const { isAuthenticated } = this.props.auth;
    const { pageTitle, isSideMenuOpen, openSideMenu, showBackButton } = this.props.app;
    const { hasLoaded, customer, hasMultipleProducts } = this.props.customer;
    const { whitePage, blueHeader } = this.props;
    const theme = whitePage ? defaultTheme : this.props.theme.theme;

    const menuItemStyles = {
      className: hasMultipleProducts ? "compressed-toremove" : "",
    };

    const iconSize = 24;  // Ensure consistency with other icons

    // Conditionally render logo based on customer type, with null check
    const renderLogo = customer && customer.customerType === "business" ? (
      <LogoBusiness color={theme.logo.color} height={20} />
    ) : (
      <Logo color={theme.logo.color} height={18} />
    );

    const unreadNotificationCount = customer ? customer.unreadNotificationCount : 0;

    return (
      <React.Fragment>
        {/* Mobile header */}
        {
          <Responsive lessThan="computer">
            <Menu secondary borderless inverted className={`main-header mobile ${menuItemStyles.className} ${blueHeader ? 'blue-header' : ''}`} role="menubar">
              <Container>
                <Menu.Item
                  disabled={hasLoaded && !customer}
                  header
                  as='h3'
                  style={{
                    margin: '0 auto 0 0',
                    backgroundColor: 'transparent'
                  }}
                  aria-label={pageTitle || "Home"} // Adding aria-label for header item
                >
                  {showBackButton && <Icon name="chevron left" onClick={() => this.props.router.navigate(-1)} />}
                  {pageTitle ? pageTitle : renderLogo}
                </Menu.Item>

                { isAuthenticated && <>
                  {!showBackButton && (
                    <>
                      <Menu.Item className="nav-icon" as={Link} to={'/help/app-support'} aria-label="Help" role="menuitem">
                        <HeaderHelpIcon color={theme.color} height={iconSize} />
                      </Menu.Item>
                      <Menu.Item className="nav-icon" as={Link} to={'/notifications'} aria-label="Notifications" role="menuitem">
                        {iconSearch("NotificationIcon", { count: unreadNotificationCount, height: iconSize })}
                      </Menu.Item>
                    </>
                  )}

                  <Menu.Item
                    active={isSideMenuOpen}
                    onClick={openSideMenu}
                    className="nav-icon"
                    aria-label="User Menu"
                    role="menuitem"
                  >
                    <UserIcon color={theme.color} height={iconSize} width={iconSize} />
                  </Menu.Item>
                </>}
              </Container>
            </Menu>
          </Responsive>
        }

        {/* Desktop header */}
        <Responsive greaterThan="tablet">
          <Menu secondary borderless inverted className={`main-header ${menuItemStyles.className} ${blueHeader ? 'blue-header' : ''}`} role="menubar">
            <Container>
              <Menu.Item header as={NavLink} to="/" replace {...menuItemStyles} aria-label="Home" role="menuitem">
                {renderLogo}
              </Menu.Item>

              {isAuthenticated && <>
                {this.props.children ? (
                  <Menu.Item style={{ minWidth: '550px' }} position="right" aria-label="Custom Content" role="menuitem">
                    {this.props.children}
                  </Menu.Item>
                ) : (
                  <React.Fragment>
                    {this.NavListItems(menuItemStyles)}
                  </React.Fragment>
                )}

                <Menu.Menu position="right" className="right-icons">
                  <Menu.Item className="nav-icon" as={Link} to={'/help/app-support'} aria-label="Help" role="menuitem">
                    <HeaderHelpIcon color={theme.color} height={iconSize} />
                  </Menu.Item>
                  <Menu.Item className="nav-icon" as={Link} to={'/notifications'} aria-label="Notifications" role="menuitem">
                    {iconSearch("NotificationIcon", { count: unreadNotificationCount, height: iconSize })}
                  </Menu.Item>
                  <Menu.Item
                    active={isSideMenuOpen}
                    onClick={openSideMenu}
                    className="nav-icon"
                    aria-label="User Menu"
                    role="menuitem"
                  >
                    <UserIcon color={theme.color} height={iconSize} width={iconSize} />
                  </Menu.Item>
                </Menu.Menu>
              </>}
            </Container>
          </Menu>
        </Responsive>
      </React.Fragment>
    );
  }
}

export default withRouter(withContexts(withTheme(Header)));
