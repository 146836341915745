import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '23px',
  width = '20px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C6.41624 0 3.5 2.79473 3.5 6.22917V9.10417H1.5C0.677515 9.10417 0 9.75345 0 10.5417V21.5625C0 22.3507 0.677515 23 1.5 23H18.5C19.3225 23 20 22.3507 20 21.5625V10.5417C20 9.75345 19.3225 9.10417 18.5 9.10417H16.5V6.22917C16.5 2.79473 13.5838 0 10 0ZM10 0.958333C13.0432 0.958333 15.5 3.31273 15.5 6.22917V9.10417H4.5V6.22917C4.5 3.31273 6.95676 0.958333 10 0.958333ZM1.5 10.0625H18.5C18.7825 10.0625 19 10.2709 19 10.5417V21.5625C19 21.8332 18.7825 22.0417 18.5 22.0417H1.5C1.21748 22.0417 1 21.8332 1 21.5625V10.5417C1 10.2709 1.21748 10.0625 1.5 10.0625ZM4 15.3333C3.73478 15.3333 3.48043 15.4343 3.29289 15.614C3.10536 15.7937 3 16.0375 3 16.2917C3 16.5458 3.10536 16.7896 3.29289 16.9693C3.48043 17.149 3.73478 17.25 4 17.25C4.26522 17.25 4.51957 17.149 4.70711 16.9693C4.89464 16.7896 5 16.5458 5 16.2917C5 16.0375 4.89464 15.7937 4.70711 15.614C4.51957 15.4343 4.26522 15.3333 4 15.3333ZM8 15.3333C7.73478 15.3333 7.48043 15.4343 7.29289 15.614C7.10536 15.7937 7 16.0375 7 16.2917C7 16.5458 7.10536 16.7896 7.29289 16.9693C7.48043 17.149 7.73478 17.25 8 17.25C8.26522 17.25 8.51957 17.149 8.70711 16.9693C8.89464 16.7896 9 16.5458 9 16.2917C9 16.0375 8.89464 15.7937 8.70711 15.614C8.51957 15.4343 8.26522 15.3333 8 15.3333ZM12 15.3333C11.7348 15.3333 11.4804 15.4343 11.2929 15.614C11.1054 15.7937 11 16.0375 11 16.2917C11 16.5458 11.1054 16.7896 11.2929 16.9693C11.4804 17.149 11.7348 17.25 12 17.25C12.2652 17.25 12.5196 17.149 12.7071 16.9693C12.8946 16.7896 13 16.5458 13 16.2917C13 16.0375 12.8946 15.7937 12.7071 15.614C12.5196 15.4343 12.2652 15.3333 12 15.3333ZM16 15.3333C15.7348 15.3333 15.4804 15.4343 15.2929 15.614C15.1054 15.7937 15 16.0375 15 16.2917C15 16.5458 15.1054 16.7896 15.2929 16.9693C15.4804 17.149 15.7348 17.25 16 17.25C16.2652 17.25 16.5196 17.149 16.7071 16.9693C16.8946 16.7896 17 16.5458 17 16.2917C17 16.0375 16.8946 15.7937 16.7071 15.614C16.5196 15.4343 16.2652 15.3333 16 15.3333Z"
        fill={color}
      />
    </svg>
  );
}