import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '20px',
  width = '20px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.19181 0C0.538486 0 0 0.542188 0 1.2V18.8C0 19.4578 0.538486 20 1.19181 20H14.6989C15.3523 20 15.8908 19.4578 15.8908 18.8V15.6L15.0962 16.4V18.8C15.0962 19.025 14.9224 19.2 14.6989 19.2H1.19181C0.968343 19.2 0.794538 19.025 0.794538 18.8V1.2C0.794538 0.973438 0.966791 0.8 1.19181 0.8H14.6989C14.9224 0.8 15.0962 0.975 15.0962 1.2V3.6L15.8908 4.4V1.2C15.8908 0.542188 15.3523 0 14.6989 0H1.19181ZM15.0341 5.2375C14.8852 5.26406 14.7657 5.37344 14.7238 5.51875C14.6819 5.66563 14.7253 5.82188 14.8355 5.925L18.4854 9.6H6.75357C6.74115 9.6 6.72874 9.6 6.71633 9.6C6.49752 9.61094 6.32682 9.79844 6.33768 10.0188C6.34854 10.2391 6.53476 10.4109 6.75357 10.4H18.4854L14.8355 14.075C14.7176 14.1719 14.6633 14.3266 14.6974 14.4766C14.7315 14.625 14.8479 14.7422 14.9953 14.7766C15.1443 14.8109 15.298 14.7563 15.3942 14.6375L19.7269 10.2875L20 10L19.7269 9.7125L15.3942 5.3625C15.3119 5.27344 15.1924 5.22813 15.0714 5.2375C15.059 5.2375 15.0466 5.2375 15.0341 5.2375Z"
        fill={color}
      />
    </svg>
  );
}
