import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '32px',
  width = '32px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V24H0.526316H20V0H0ZM1.05263 1.04348H18.9474V22.9565H1.05263V1.04348ZM3.68421 4.69565V5.73913H16.3158V4.69565H3.68421ZM3.68421 12V13.0435H11.0526V12H3.68421ZM13.6842 12V13.0435H16.3158V12H13.6842ZM3.68421 15.1304V16.1739H11.0526V15.1304H3.68421ZM13.6842 15.1304V16.1739H16.3158V15.1304H13.6842ZM3.68421 18.2609V19.3043H11.0526V18.2609H3.68421ZM13.6842 18.2609V19.3043H16.3158V18.2609H13.6842Z"
        fill={color}
      />
    </svg>
  );
}
