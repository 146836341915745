import { createContext, useContext, useReducer, useEffect } from 'react';

// Base themes for light and dark modes
let lightThemeBase = {
  primary: {
    backgroundColor: "#FC7D00",
    color: '#0060ae',
  },
  secondary: {
    backgroundColor: "#E7F1F8",
    background: "linear-gradient(to right, #1a7bbb, #0868b2)",
  },
  tertiary: {
    backgroundColor: "#0060ae",
  },
  quaternary: {
    backgroundColor: "#E7F1F8",
    background: "linear-gradient(to right, #1a7bbb, #0868b2)",
  },
  important: {
    backgroundColor: "#f7902b",
    color: 'red',
  },
  divider: '1px solid #F2F2F2',
  border: '1px solid #F2F2F2',
  borderColored: '1px solid #0060ae',
  positive: '#37BF3D',
  negative: '#FF4949',
  notification: {
    header: {
      background: '#0060AE',
      color: 'white',
    },
    description: {
      color: '#000000',
    },
    extra: {
      color: '#9B9B9B',
    }
  },
  TimeOfUseGraph: {
    color: '#0060ae',
    offPeakColor: '#F7902B',
  },
  card: {
    hero: {
      background: '#f7902b',
      color: 'white',
    },
    primary: {
      background: '#F0EFEE',
      color: 'white',
    },
    default: {
      background: '#FFFFFF',
      color: '#0060AE',
    },
    faint: {
      background: '#F5F5F5',
      color: '#0060AE',
    },
    graph: {
      background: '#FFFFFF',
      color: '#222222',
      padding: '0',
    },
    message: {
      background: 'none',
      color: '#0060AE',
    },
  },
  sidebar: {
    background: "#F0EFEE",
    color: "#0060AE",
    item: {
      default: {
        background: "#FFFFFF",
        color: "#0F4172",
      },
      highlight: {
        background: "#0060AE",
        color: "white",
      },
      switched: {
        background: "#F7902B",
        color: "#0F4172",
      }
    }
  },
  tabs: {
    active: {
      background: '#FFFFFF',
      color: '#0060AE',
    },
    inactive: {
      background: '#0060AE',
      color: '#FFFFFF',
    },
    pane: {
      background: '#FFFFFF',
      color: '#0060AE',
    },
  },
  transitionList: {
    color: '#0060ae',
  },
  table: {
    alternateBackground: '#F3F3F3',
  },
  chart: {
    yAxis: {
      color: '#000000',
    },
    xAxis: {
      color: '#0060ae',
    },
  },
  navigation: {
    background: "#F6F6F6",
    color: "#0060AE",
    iconColor: "#0060AE",
    active: {
      background: "#F78F28",
      color: "#F78F28",
      iconColor: "#F78F28"
    }
  },
  footer: {
    background: "#232323",
    color: "#ffffff",
  },
  segment: {
    backgroundColor: "#ffffff",
    color: "#0060ae",
    border: "0px solid #ffffff",
    margin: "20px"
  },
  addacard: {
    backgroundColor: "#ffffff",
    color: "#0060ae",
    border: "0px solid #ffffff",
    marginTop: "20px",
    borderRadius: "10px"
  },
  transactionHistory: {
    tabs: {
      active: {
        background: '#FFFFFF',
        color: '#0060AE',
      },
      inactive: {
        background: '#0060AE',
        color: '#FFFFFF',
      },
    },
    item: {
      default: {
        background: '#FFFFFF',
        color: '#0060ae',
      },
      alternate: {
        background: '#FFFFFF',
        color: '#0060ae',
      },
      odd: {
        background: '#f7f7f7',
        color: '#0060ae',
      },
      even: {
        background: '#fff',
        color: '#0060ae',
      },
      highlighted: {
        background: '#f2f2f2',
        color: '#0060ae',
      },
      amount: {
        positive: '#0060ae',
        negative: '#0060ae',
      },
      link: {
        color: '#0060AE',
        hoverColor: '#004080',
        textDecoration: 'none',
      },
    },
  },
  powerHoursBannerBackground: {
    backgroundColor: '#FFFFFF',
  },
  paymentHelp: {
    backgroundColor: '#FFFFFF', 
  },
  addCardLayout: {
    backgroundColor: '#FFFFFF', 
    labelColor: '#333333',      
  },
  contactPage: {
      color: '#333333',
      backgroundColor: '#FFFFFF',
    links: {
      color: '#0060ae',
      hoverColor: '#004080',
    },
    formFields: {
      backgroundColor: '#f0f0f0',
      borderColor: '#CCCCCC',
      textColor: '#333333',
    },
    header: {
      color: '#0060ae',
    },
  },
  modal:{
    color: '#0060ae',
    backgroundColor: '#FFFFFF',
  },
  layout: {
    backgroundColor: '#ffffff',
    color: '#333333',
    // Add any other layout-specific properties you want to control
  },
};

let darkThemeBase = {
  primary: {
    backgroundColor: "#f7902b",
    color: '#ffffff',
  },
  secondary: {
    backgroundColor: "#E7F1F8",
    background: "linear-gradient(to right, #1a7bbb, #0868b2)",
  },
  tertiary: {
    backgroundColor: "#0060ae",
  },
  quaternary: {
    backgroundColor: "#E7F1F8",
    background: "linear-gradient(to right, #1a7bbb, #0868b2)",
  },
  important: {
    backgroundColor: "#f7902b",
    color: 'red'
  },
  divider: '1px solid #333333',
  border: '1px solid #333333',
  borderColored: '1px solid #ffffff',
  positive: '#37BF3D',
  negative: '#FF4949',
  notification: {
    header: {
      background: '#E6E6E6',
      color: 'black',
    },
    description: {
      color: 'white',
    },
    extra: {
      color: '#9B9B9B',
    }
  },
  TimeOfUseGraph: {
    color: '#FFFFFF',
    offPeakColor: '#F7902B',
  },
  card: {
    hero: {
      background: '#25262A',
      color: 'white',
    },
    primary: {
      background: '#F0EFEE',
      color: 'white',
    },
    default: {
      background: '#25262A',
      color: 'white',
    },
    faint: {
      background: '#25262A',
      color: 'white',
    },
    graph: {
      background: '#25262A',
      color: 'white',
      padding: '0',
    },
    message: {
      background: 'none',
      color: 'white',
    },
  },
  sidebar: {
    background: "#0F0F0F",
    color: "#ffffff",
    item: {
      default: {
        background: "#232323",
        color: "#fff",
      },
      highlight: {
        background: "#0060AE",
        color: "#FFFFFF",
      },
      switched: {
        background: "#F7902B",
        color: "#000000",
      }
    }
  },
  tabs: {
    active: {
      background: '#25262A',
      color: '#FFFFFF',
    },
    inactive: {
      background: '#CCCCCC',
      color: '#101010',
    },
    pane: {
      background: '#25262A',
      color: '#FFFFFF',
    },
  },
  transitionList: {
    color: '#ffffff',
  },
  table: {
    alternateBackground: '#333333',
    color: '#ffffff!important'
  },
  chart: {
    yAxis: {
      color: '#9B9B9B',
    },
    xAxis: {
      color: '#9B9B9B',
    },
  },
  navigation: {
    background: "#181818",
    color: "#E32227",
    iconColor: "#ffffff",
    active: {
      background: "#ffffff",
      color: "#F78F28",
      iconColor: "#F78F28"
    }
  },
  footer: {
    background: "#060606",
    color: "#ffffff",
  },
  contactPage: {
      color: '#FFFFFF',
      backgroundColor: '#1E1E1E',
    links: {
      color: '#ffffff',
      hoverColor: '#0a52d8',
    },
    formFields: {
      backgroundColor: '#333333',
      borderColor: '#555555',
      textColor: '#FFFFFF',
    },
    header: {
      color: '#ffffff',
    },
  },
  segment: {
    backgroundColor: "#25262A",
    color: "#FFFFFF",
    border: "0px solid #ffffff",
    margin: "20px"
  },
  addacard: {
    backgroundColor: "#ffffff",
    color: "#0060ae",
    border: "0px solid #ffffff",
    marginTop: "20px",
    borderRadius: "10px"
  },
  transactionHistory: {
    tabs: {
      active: {
        background: '#25262A',
        color: '#FFFFFF',
      },
      inactive: {
        background: '#F4F4F4',
        color: '#333333',
      },
    },
    link: {
      color: '#FFFFFF',
      hoverColor: '#F2F2F2',
      textDecoration: 'none',
    },
    item: {
      default: {
        background: '#25262A',
        color: '#FFFFFF',
      },
      alternate: {
        background: '#25262A',
        color: '#FFFFFF',
      },
      odd: {
        background: '#25262A',
        color: '#FFFFFF',
      },
      even: {
        background: '#25262A',
        color: '#FFFFFF',
      },
      highlighted: {
        background: '#666666',
        color: '#FFFFFF',
      },
      amount: {
        positive: '#FFFFFF',
        negative: '#FFFFFF',
      },
    },
  },
  paymentHelp: {
    backgroundColor: '#25262A', 
  },
  powerHoursBannerBackground: {
    backgroundColor: '#25262A',  
  },
  addCardLayout: {
    backgroundColor: '#FFFFFF', // Default background color for "Add a Card" layout
    labelColor: '#333333',      // Default label color for the "Add a Card" layout
  },
  AccountPicker:{
    backgroundColor: '#333333',
    border: '1px solid #333333',
  },
  modal:{
    color: '#FFFFFF',
    backgroundColor: '#333333',
  },
  layout: {
    backgroundColor: '#1a1a1a',
    color: '#ffffff',
    // Add any other layout-specific properties you want to control
  },
};

// Theme definitions for different contexts
export const themes = {
  "residential-light": {
    name: "residential",
    ...lightThemeBase,
    background: "#F3F3F3",
    color: "#0060AE",
    logo: {
      color: "#0060AE",
    },
    card: {
      ...lightThemeBase.card,
      hero: {
        background: '#f7902b',
        color: '#0F4172',
      },
      primary: {
        background: '#0060AE',
        color: 'white',
      },
      default: {
        background: '#FFFFFF',
        color: '#0060AE',
      },
    },
  },

  "business-light": {
    name: "business",
    ...lightThemeBase,
    background: "#F3F3F3",
    color: "#0F4172",
    logo: {
      color: "#0F4172",
    },
    sidebar: {
      background: "#F3F3F3",
      color: "#0F4172",
      item: {
        default: {
          background: "#FFFFFF",
          color: "#0F4172",
        },
        highlight: {
          background: "linear-gradient(66deg, #091931 34.35%, #1C4D97 92.2%)",
          color: "#FFFFFF",
        },
        switched: {
          background: "#0F4172",
          color: "#FFFFFF",
        }
      }
    },
    card: {
      ...lightThemeBase.card,
      hero: {
        background: "linear-gradient(66deg, #091931 34.35%, #1C4D97 92.2%)",
        color: 'white',
      },
      primary: {
        background: "linear-gradient(66deg, #091931 34.35%, #1C4D97 92.2%)",
        color: 'white',
      },
      default: {
        background: '#FFFFFF',
        color: '#091931',
      },
    },
    TimeOfUseGraph: {
      color: '#0F4172',
      offPeakColor: '#0F4172',
    },
    navigation: {
      background: "#F6F6F6",
      color: "#0F4172",
      iconColor: "#0F4172",
      active: {
        background: "#F78F28",
        color: "#F78F28",
        iconColor: "#F78F28"
      }
    },
  },
  "residential-dark": {
    name: "residential",
    ...darkThemeBase,
    background: '#0F0F0F',
    color: "white",
    logo: {
      color: "white",
    },
    card: {
      ...darkThemeBase.card,
      hero: {
        background: '#F78F28',
        color: '#1E1E1E',
      },
      primary: {
        background: '#0060AE',
        color: 'white',
      },
      default: {
        background: '#25262A',
        color: 'white',
      },
    },
    powerHoursBannerBackground: {
      backgroundColor: '#25262A',  
    }
  },
  "business-dark": {
    name: "business",
    ...darkThemeBase,
    background: '#0F0F0F',
    color: "white",
    logo: {
      color: "white",
    },
    sidebar: {
      background: "#0F0F0F",
      color: "#f2f2f2",
      item: {
        default: {
          background: "#25262A",
          color: "#f2f2f2",
        },
        highlight: {
          background: "#0F4172",
          color: "#f2f2f2",
        },
        switched: {
          background: "#0F4172",
          color: "#f2f2f2",
        }
      }
    },
    card: {
      ...darkThemeBase.card,
      hero: {
        background: '#f7902b',
        color: 'white',
      },
      primary: {
        background: "linear-gradient(58deg, #091931 37.73%, #1C4D97 93.44%)",
        color: 'white',
      },
      default: {
        background: '#25262A',
        color: 'white',
      },
    },
    TimeOfUseGraph: {
      color: '#ffffff',
      offPeakColor: '#ffffff',
    },
  },
};

export const defaultTheme = themes["residential-light"];

// Initial state
const initialState = {
  theme: themes["residential-light"],
  customerType: "residential",
  darkMode: false,
  setCustomerType: () => null,
  setDarkMode: () => null,
};

// Reducer function
function themeReducer(state, payload) {
  return { ...state, ...payload };
}

// Get theme based on customer type and dark mode
function getTheme(customerType, darkMode) {
  const themeName = customerType + "-" + (darkMode ? "dark" : "light");
  return themes[themeName];
}

// Theme context and provider
export const ThemeContext = createContext(initialState);

export function ThemeProvider({ children }) {
  const [state, setState] = useReducer(themeReducer, initialState);

  useEffect(() => {
    const savedCustomerType = localStorage.getItem('customerType') || 'residential';
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setState({
      customerType: savedCustomerType,
      darkMode: savedDarkMode,
      theme: getTheme(savedCustomerType, savedDarkMode)
    });
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = state.theme.background;
  }, [state.theme]);

  useEffect(() => {
    localStorage.setItem('customerType', state.customerType);
    localStorage.setItem('darkMode', state.darkMode);
  }, [state.customerType, state.darkMode]);

  const theme = {
    ...state,
    setState,
    setCustomerType: (customerType) =>
      customerType === state.customerType
        ? null
        : setState({
            customerType,
            theme: getTheme(customerType, !!state.darkMode)
          }),
    setDarkMode: (darkMode) =>
      darkMode === state.darkMode
        ? null
        : setState({
            darkMode,
            theme: getTheme(state.customerType, !!darkMode)
          }),
  };

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
}

// Hook to use theme context
export const useTheme = () => useContext(ThemeContext);

// Higher-order component (HOC) to provide theme context
export function withTheme(Component) {
  return function WrappedComponent(props) {
    return (
      <ThemeContext.Consumer>
        {(theme) => <Component {...props} theme={theme} />}
      </ThemeContext.Consumer>
    );
  };
}
