import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M17.3077 0V22H25V0H17.3077ZM19.2308 1.83333H23.0769V20.1667H19.2308V1.83333ZM0 5.5V22H7.69231V5.5H0ZM1.92308 7.33333H5.76923V20.1667H1.92308V7.33333ZM8.65385 11V22H16.3462V11H8.65385ZM10.5769 12.8333H14.4231V20.1667H10.5769V12.8333Z" />
    </svg>
)
}
