import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '32px',
  width = '32px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1793 0C10.1097 0 10.045 0.00994319 9.98041 0.0323152C4.28549 1.93643 0 7.2983 0 13.642C0 21.5643 6.43569 28 14.3579 28C20.7016 28 26.0634 23.7145 27.9675 18.0195C28.0545 17.7585 27.9626 17.4702 27.7413 17.3061C27.5176 17.142 27.2168 17.142 26.9931 17.3011C25.0293 18.7156 22.6504 19.7074 20.1025 19.7074C13.5724 19.7074 8.29257 14.4276 8.29257 7.89737C8.29257 5.34943 9.28439 2.97053 10.6988 1.00923C10.838 0.812855 10.8554 0.556818 10.746 0.345526C10.6367 0.131747 10.4179 0 10.1793 0ZM8.66792 2.12287C7.73575 3.87287 7.01985 5.77699 7.01985 7.89737C7.01985 15.1161 12.8838 20.9801 20.1025 20.9801C22.2229 20.9801 24.127 20.2642 25.8795 19.3295C23.7144 23.6101 19.4438 26.7273 14.3579 26.7273C7.12425 26.7273 1.27272 20.8757 1.27272 13.642C1.27272 8.55859 4.38989 4.28551 8.66792 2.12287Z"
        fill={color}
      />
    </svg>
  );
}