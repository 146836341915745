import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '22px',
  width = '22px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78772 0C4.26478 0 3.83149 0.415365 3.83149 0.916667V1.83333H0.962774C0.439832 1.83333 0.00653685 2.2487 0.00653685 2.75V10.5417C0.00466932 10.7064 0.0943166 10.8604 0.243729 10.9445C0.393141 11.0269 0.57617 11.0269 0.725583 10.9445C0.874995 10.8604 0.964642 10.7064 0.962774 10.5417V7.33333H21.0438V21.0833H0.962774V17.4167C0.964642 17.252 0.874995 17.098 0.725583 17.0138C0.57617 16.9315 0.393141 16.9315 0.243729 17.0138C0.0943166 17.098 0.00466932 17.252 0.00653685 17.4167V21.0833C0.00653685 21.5846 0.439832 22 0.962774 22H21.0438C21.5667 22 22 21.5846 22 21.0833V2.75C22 2.2487 21.5667 1.83333 21.0438 1.83333H18.1751V0.916667C18.1751 0.415365 17.7418 0 17.2188 0H16.2626C15.7396 0 15.3063 0.415365 15.3063 0.916667V1.83333H6.7002V0.916667C6.7002 0.415365 6.2669 0 5.74396 0H4.78772ZM4.78772 0.916667H5.74396V3.66667H4.78772V2.36865C4.79706 2.31852 4.79706 2.26839 4.78772 2.22005V0.916667ZM16.2626 0.916667H17.2188V2.21468C17.2095 2.26481 17.2095 2.31494 17.2188 2.36328V3.66667H16.2626V0.916667ZM0.962774 2.75H3.83149V3.66667C3.83149 4.16797 4.26478 4.58333 4.78772 4.58333H5.74396C6.2669 4.58333 6.7002 4.16797 6.7002 3.66667V2.75H15.3063V3.66667C15.3063 4.16797 15.7396 4.58333 16.2626 4.58333H17.2188C17.7418 4.58333 18.1751 4.16797 18.1751 3.66667V2.75H21.0438V6.41667H0.962774V2.75ZM7.08867 10.146C6.89444 10.1424 6.71701 10.2516 6.63857 10.4217C6.56199 10.5936 6.60121 10.7923 6.73755 10.9266L9.09826 13.2917H0.484656C0.312832 13.2899 0.152214 13.3758 0.0644342 13.519C-0.021478 13.6623 -0.021478 13.8377 0.0644342 13.981C0.152214 14.1242 0.312832 14.2101 0.484656 14.2083H8.86854L6.73755 16.3442C6.55452 16.5286 6.56013 16.8187 6.75063 16.9924C6.94299 17.1678 7.24555 17.1624 7.42672 16.9798L10.2207 14.1797C10.3589 14.1296 10.4635 14.0239 10.5083 13.8914L10.7642 13.6354L7.42858 10.291C7.3408 10.2015 7.21754 10.1478 7.08867 10.146Z"
        fill={color}
      />
    </svg>
  );
}
