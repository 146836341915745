import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '11px',
  width = '20px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.059 11L20 10.0539L10 8.74228e-07L-8.27093e-08 10.0539L0.941011 11L10 1.89217L19.059 11Z"
        fill={color}
      />
    </svg>
  );
}
