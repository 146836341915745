import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { withRouter } from '../../utils/useRouter';
import Device from "../../utils/Device";
import NotificationIcon from "../Icons/NotificationIcon";
import UserIcon from "../Icons/UserIcon";
import PayNowIcon from "../Icons/PayNowIcon";
import BillsAndPaymentsIcon from "../Icons/BillsAndPaymentsIcon";
import ChevronUp from "../Icons/ChevronUp";
import ChevronDown from "../Icons/ChevronDown";
import BillIcon from "../Icons/BillIcon";
import AddPropertyIcon from "../Icons/AddPropertyIcon";
import HelpIcon from "../Icons/HelpIcon";
import DarkModeIcon from "../Icons/DarkModeIcon";
import ContactUsIcon from "../Icons/ContactUsIcon";
import TransactionHistoryIcon from "../Icons/TransactionHistoryIcon";
import CreditCardIcon from "../Icons/CreditCardIcon";
import PaymentExtensionIcon from "../Icons/PaymentExtensionIcon";
import ChangePasswordIcon from "../Icons/ChangePasswordIcon";
import LogOutIcon from "../Icons/LogOutIcon";
import Analytics from "../../utils/Analytics";

import "./SideMenu.css";
import { Button, Sidebar, Menu, Segment, Icon } from "semantic-ui-react";

import { withApp } from "./../../contexts/App";
import { withCustomer } from "./../../contexts/Customer";
import { withTheme } from "./../../contexts/Theme";
import Modal from "./../Modal";
import AuroraEnergyLogo from '../Logo/AuroraEnergyLogo';

const BILLING_TYPE = {
  POSTPAID: "POSTPAID",
  PAYG: "PAYG"
};

export class SideMenu extends React.Component {
  state = {
    visible: false,
    modalLoggedOutOpen: false,
    version_web: "",
    version_api: "",
    version_app: "",
    windowWidth: window.innerWidth,
    isPaymentOptionsExpanded: false,
    isLoading: false,
  };

  toggleBalance = (e) => {
    e.preventDefault();
    const { customerPremise, refresh } = this.props.customer;
    const url = process.env.REACT_APP_API_BASE_URI + `premises/${customerPremise.parentAccountID}/experience`;

    const newExperience = customerPremise.hasPrePayBilling
      ? BILLING_TYPE.POSTPAID
      : BILLING_TYPE.PAYG;

    this.setState({ isLoading: true });
    axios.post(url, { CurrentAppExperience: newExperience }).then(res => {
      refresh().then(() => this.props.router.navigate("/"));
    });

    if (newExperience === BILLING_TYPE.POSTPAID) {
      Analytics.event({ category: "Switch", action: "Postpay UX successful" });
    } else {
      Analytics.event({ category: "Switch", action: "Prepay UX successful" });
    }

  };

  togglePaymentOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      isPaymentOptionsExpanded: !prevState.isPaymentOptionsExpanded
    }));
  };

  toggleDarkMode = () => {
    const { darkMode, setDarkMode } = this.props.theme;
    setDarkMode(!darkMode);
  };

  NavListItems = props => {
    const { customer, customerPremise, hasMultipleProducts, pageConfig } = this.props.customer;
    const { theme, darkMode } = this.props.theme;

    if (!pageConfig || !pageConfig.menuItems) return null;

    const menuItems = [
      {
        text: "Menu",
        icon: "chevron right",
        onClick: this.props.app.closeSideMenu,
        className: "sidebar-menu-header",
        style: { backgroundColor: "transparent" },
      },
      {
        text: customer ? `${customer.firstName} ${customer.lastName}` : "",
        subText: "Account details",
        icon: "UserIcon",
        highlight: true,
        url: "/accountSettings",
      },
      { text: "Make payment", icon: "PayNowIcon", url: "/payment" },
      {
        text: "Bills & transactions",
        icon: "BillIcon",
        url: "/transactionhistory/bills",
      },
      {
        text: "Account management",
        icon: this.state.isPaymentOptionsExpanded ? "ChevronUp" : "ChevronDown",
        className: this.state.isPaymentOptionsExpanded ? "expanded" : "",
        onClick: this.togglePaymentOptions,
        keepOpen: true,
        subItems: [
          ...(customer.customerType === "residential"
            ? [
                {
                  text: "Payment extension",
                  icon: "PaymentExtensionIcon",
                  url: "/payment-help",
                  onClick: (e) => {
                    e.preventDefault();
                    this.props.router.navigate("/payment-help");
                    this.props.app.closeSideMenu(); // Close the sidebar
                  },
                },
              ]
            : []),
          {
            text: "Card details",
            icon: "CreditCardIcon",
            url: "/accountSettings/payments",
            onClick: (e) => {
              e.preventDefault();
              this.props.router.navigate("/accountSettings/payments");
              this.props.app.closeSideMenu(); // Close the sidebar
            },
          },
        ],
      },
      {
        text: "Add property to aurora+",
        icon: "AddPropertyIcon",
        url: "/signup/property",
      },
      {
        row: true,
        key: "toggles",
        subItems: [
          ...(customer.customerType === "residential"
            ? [
                {
                  text: customerPremise && customerPremise.hasPrePayBilling ? "Hide balance" : "Show balance",
                  icon: "PayNowIcon",
                  onClick: this.toggleBalance,
                  keepOpen: true,
                  className: "toggle-button",
                  switched: customerPremise && customerPremise.hasPrePayBilling,
                },
                {
                  text: darkMode ? "Light mode" : "Dark mode",
                  icon: "DarkModeIcon",
                  onClick: this.toggleDarkMode,
                  keepOpen: true,
                  className: "toggle-button",
                  highlight: darkMode,
                },
              ]
            : [
                {
                  text: darkMode ? "Light mode" : "Dark mode",
                  icon: "DarkModeIcon",
                  onClick: this.toggleDarkMode,
                  keepOpen: true,
                  highlight: darkMode,
                  className: "businessLightDarkToggle"
                },
              ]),
        ],
      },
      { text: "Contact us", icon: "ContactUsIcon", url: "/contact" },
      {
        text: "Change password",
        icon: "ChangePasswordIcon",
        url: "/accountSettings/changePassword",
      },
      { text: "Log out", icon: "LogOutIcon", onClick: this.handleOpenModal },
    ];

    const renderIcon = (iconName) => {
      switch (iconName) {
        case 'UserIcon':
          return <UserIcon height={24} />;
        case 'PayNowIcon':
          return <PayNowIcon height={24} />;
        case 'BillIcon':
          return <BillIcon height={24} width={24} />;  
        case 'DarkModeIcon':
            return <DarkModeIcon height={24} width={24} />;  
        case 'NotificationIcon':
          return <NotificationIcon />;
        case 'BillsAndPaymentsIcon':
          return <BillsAndPaymentsIcon height={24} />;
        case 'ChevronUp':
          return <ChevronUp height={24} />;
        case 'ChevronDown':
          return <ChevronDown height={24} />;
        case 'ContactUsIcon':
          return <ContactUsIcon height={24} width={24} />;
        case 'AddPropertyIcon':
          return <AddPropertyIcon height={24} />;
        case 'HelpIcon':
          return <HelpIcon />;
        case 'ChangePasswordIcon':
          return <ChangePasswordIcon />;
        case 'TransactionHistoryIcon':
          return <TransactionHistoryIcon />;
        case 'PaymentExtensionIcon':
          return <PaymentExtensionIcon />;
        case 'CreditCardIcon':
          return <CreditCardIcon />;
        case 'LogOutIcon':
          return <LogOutIcon />;
        default:
          return <Icon name={iconName} />;
      }
    };

    const renderMenuItem = (item) => (
      <React.Fragment key={item.key || item.text}>
        {item.row ?
          <div className="sidemenu-row">
            {item.subItems && item.subItems.map(subItem => renderMenuItem(subItem))}
          </div>
        : <>
          <Menu.Item
            style={{
              ...theme.sidebar.item.default,
              ...(item.switched && theme.sidebar.item.switched),
              ...(item.highlight && theme.sidebar.item.highlight),
              ...item.style
            }}
            as={item.subItems ? 'div' : item.onClick ? 'a' : NavLink}
            to={item.url}
            onClick={(e) => {
              if (item.onClick) item.onClick(e);
              if (!item.keepOpen) this.props.app.closeSideMenu();
            }}
            className={`${item.className ? item.className : ''} ${item.subItems ? 'expander' : ''}`}
          >
            <div className="menu-item-content">
              <div className="menu-item-text">
                <span>{item.text}</span>
                {item.subText && <span className="sub-text">{item.subText}</span>}
              </div>
              {renderIcon(item.icon)}
            </div>
            { item.subItems && (
              <div className="menu-sub-items">
                {item.subItems.map((subItem, subIndex) => (
                  <Menu.Item
                    style={{
                      ...theme.sidebar.item.default,
                      ...(subItem.switched && theme.sidebar.item.switched),
                      ...(subItem.highlight && theme.sidebar.item.highlight),
                      ...subItem.style
                    }}
                    key={`sub-${subIndex}`}
                    as={NavLink}
                    to={subItem.url}
                    onClick={(e) => {
                      subItem.onClick && subItem.onClick(e);
                      if (!subItem.keepOpen) this.props.app.closeSideMenu();
                    }}
                    className="sub-item"
                  >
                    <div className="menu-item-content">
                      <div className="menu-item-text">
                        <span>{subItem.text}</span>
                      </div>
                      {renderIcon(subItem.icon)}
                    </div>
                  </Menu.Item>
                ))}
              </div>            
            )}
          </Menu.Item>
        </>}
      </React.Fragment>
    );

    return (
      <>
        {menuItems.map(item => renderMenuItem(item))}
      </>
    );
  };

  componentDidMount() {
    this.setState({
      version_web: Array.from(document.getElementsByTagName("meta"))
        .filter(e => e.name === "ui-version")
        .map(e => e.content)
        .pop()
    });

    axios.post(process.env.REACT_APP_API_BASE_URI + "status").then(resp => {
      this.setState({ version_api: resp.data.APIVersion });
    });

    window.addEventListener('resize', this.handleResize);

    if ("ontouchstart" in document.documentElement && Device.isAppleDevice()) {
      document.body.style.cursor = "pointer";
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleOpenModal = () => {
    this.setState({ modalLoggedOutOpen: true });
  };

  handleCloseModal = () => {
    this.props.app.closeSideMenu();
    this.setState({ modalLoggedOutOpen: false });
  };

  logout = () => {
    this.handleCloseModal();
    this.props.logoutUser();
    this.props.router.navigate("/");
  };

  renderVersion = () => {
    const { deviceInfo, isApp } = this.props.app;

    if (isApp && deviceInfo) {
      if (deviceInfo.environment === "PRODUCTION") {
        return <span>WEB: {this.state.version_web} API: {this.state.version_api || "..."} APP: {deviceInfo.appVersion}</span>;
      } else {
        return <span>WEB: {this.state.version_web} API: {this.state.version_api || "..."} APP: {deviceInfo.appVersion}<br />{deviceInfo.environment}</span>;
      }
    }
    if (process.env.NODE_ENV === "production") {
      return <span>WEB: {this.state.version_web} API: {this.state.version_api || "..."}</span>;
    } else {
      return <span>WEB: {this.state.version_web} API: {this.state.version_api || "..."}<br />{process.env.NODE_ENV}</span>;
    }
  };

  renderDesktopMenu = () => {
    const { isSideMenuOpen, closeSideMenu } = this.props.app;
    const { theme } = this.props.theme;
    const { windowWidth } = this.state;
    const mobileWidth = 453;

    return (
      <Sidebar
        style={{
          width: windowWidth < mobileWidth ? "100%" : "400px",
          padding: '20px',
          backgroundColor: theme.sidebar.background,
          color: theme.sidebar.color,
        }}
        as={Menu}
        animation="overlay"
        direction="right"
        vertical
        onHide={closeSideMenu}
        visible={isSideMenuOpen}
      >
        {this.NavListItems()}

        <Menu.Item
          key="aurora-logo"
          className="aurora-energy-section" // Apply the new class
          header
        >
          <AuroraEnergyLogo color={theme.sidebar.color} />
          <p onClick={() => window.location.reload(true)}>Version {this.renderVersion()}</p>
          <small>© 2024 Aurora Energy Pty Ltd ABN 85 082 464 622</small>
        </Menu.Item>
      </Sidebar>
    );
  };

  render() {
    const { isSideMenuOpen } = this.props.app;
    const { theme } = this.props.theme;

    return (
      <React.Fragment>
        <Modal
          name="side-menu/logged-out"
          size="mini"
          open={this.state.modalLoggedOutOpen}
          onClose={this.logout}
        >
          <Modal.Header>You have been logged out</Modal.Header>
          <Modal.Content>
            <Modal.Description>You have been successfully logged out.</Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button style={{ backgroundColor: theme.primary.backgroundColor }} secondary fluid onClick={this.logout}>OK</Button>
          </Modal.Actions>
        </Modal>
        <Sidebar.Pushable as={Segment} basic style={{ margin: 0, overflow: isSideMenuOpen ? "hidden" : undefined }}>
          {this.renderDesktopMenu()}
          <Sidebar.Pusher dimmed={isSideMenuOpen}>
            {this.props.children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </React.Fragment>
    );
  }
}

export default withRouter(withCustomer(withTheme(withApp(SideMenu))));
