import React from 'react';
export default function Icon({
    color = 'currentColor',
    height = '32px',
    width = height
}) { return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 26 24"
      
      xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M13 0L12.3189 0.649446L0 12.9299L1.36219 14.2878L2.57631 13.0775V24H11.1048V14.5535H14.8952V24H23.4237V13.0775L24.6378 14.2878L26 12.9299L13.6811 0.649446L13 0ZM13 2.68635L21.5285 11.1882V22.1107H16.7904V12.6642H9.20957V22.1107H4.47153V11.1882L13 2.68635Z" />
    </svg>
)
}
