import React from 'react';

export default function Icon({
  color = 'currentColor',
  height = '24px',
  width = '24px'
}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.37876 0 0 5.37876 0 12C0 18.6212 5.37876 24 12 24C18.6212 24 24 18.6212 24 12C24 5.37876 18.6212 0 12 0ZM12 1.04348C18.0573 1.04348 22.9565 5.9427 22.9565 12C22.9565 18.0573 18.0573 22.9565 12 22.9565C5.9427 22.9565 1.04348 18.0573 1.04348 12C1.04348 5.9427 5.9427 1.04348 12 1.04348ZM11.4783 5.73913V11.4783H5.73913V12.5217H11.4783V18.2609H12.5217V12.5217H18.2609V11.4783H12.5217V5.73913H11.4783Z"
        fill={color}
      />
    </svg>
  );
}
